<template>
    <All model="Connection" :immediate="true" ref="connectionsRef" v-slot="{data:connections}" :query="connectionsFilter" @loading:change="(event) => loading = event">
        <SectionWrapper>
            <div class="w-full px-1 sm:px-2 xl:px-20">
                <Header :title="title"/>
                <div class="py-2 w-full flex flex-row items-between justify-center">
                    <perPageSelector @changeOption="changePerPage"/>
                    <div class="w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6">
                        <div v-if="elSelected && elSelected.name === 'google' && elSelected.data_is_null" class="flex flex-row">
                            <button @click="googleConnect" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
                                Conectar
                            </button>
                        </div>
                        <div v-if="elSelected && elSelected.name === 'hubspot'" class="flex flex-row">
                            <mode-mod @refreshDataTable="refreshDataTable"/>
                            <status-mod v-if="elSelected.mode === 'oAuth2'" @modalAction="refreshDataTable"/>
                        </div>
                    </div>
                    <searcher @changeText="changeText"/>
                </div>
                <Table
                    :data="connections.data" :fields="fields" :fieldBy="connectionsFilter.field"
                    :direction="connectionsFilter.direction" :loading="loading"
                    @changeField="changeField" @changeDirection="changeDirection"/>
                <paginate v-if=connections.meta :meta=connections.meta @changePage="changePage"/>
                <PaginateTextInfo v-if="connections.meta" :meta="connections.meta" class="flex-auto text-center sm:hidden"/>
                <paginate-mobile :links="connections.links" @changePage="changePage" class="sm:hidden"/>
            </div>
        </SectionWrapper>
    </All>
</template>

<script>
import {All} from '@/api/components';
import PerPageSelector from "@/pages/inside/shared/datatables/PerPageSelector";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import Table from "@/pages/inside/sections/connections/Table";
import Paginate from "@/pages/inside/shared/datatables/Paginate";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import ModeMod from "@/pages/inside/sections/connections/modals/Mode";
import StatusMod from "@/pages/inside/sections/connections/modals/Status";
import {actions, state} from "@/store";
import Header from "@/pages/inside/shared/datatables/Header";
import SectionWrapper from "@/pages/inside/shared/slots/SectionWrapper";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
export default {
    name: "Connections",
    components:{
        All, SectionWrapper, Header, Paginate, PaginateMobile, PerPageSelector, Searcher, Table, ModeMod, StatusMod, PaginateTextInfo
    },
    data() {
        return {
            title: 'Conexiones',
            loading: false,
            connectionsFilter: {
                page: null,
                perPage: state.visibleRowsQtyInTable,
                field: 'name',
                direction: 'asc',
                text: null
            },
            fields,
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
    },
    mounted() {
        actions.setElSelected(false);
    },
    methods: {
        refreshDataTable(keepSelection = false) {
            if (!keepSelection) {
                actions.setElSelected(false);
            }
            this.$refs.connectionsRef.request();
        },
        changePage(page){
            this.connectionsFilter.page = page;
            this.refreshDataTable();
        },
        changePerPage(option){
            this.connectionsFilter.perPage = option;
            this.connectionsFilter.page = 1;
            this.refreshDataTable();
        },
        changeField(field){
            this.connectionsFilter.field = field;
            this.refreshDataTable();
        },
        changeDirection(direction){
            this.connectionsFilter.direction = direction;
            this.refreshDataTable();
        },
        changeText(text){
            this.connectionsFilter.page = null;
            this.connectionsFilter.text = text;
            this.refreshDataTable();
        },
        googleConnect() {
            window.location.href = process.env.VUE_APP_GOOGLE_URL;
        }
    }
};

const fields = [
    {
        label: 'Conexión',
        key: 'name'
    },
    {
        label: 'Descripción',
        key: 'description'
    },
    {
        label: 'Tipo',
        key: 'mode'
    },
    {
        label: 'Creado',
        key: 'created_at'
    },
    {
        label: 'Actualizado',
        key: 'updated_at'
    },
];
</script>

<style scoped>

</style>
<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-eye text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'lg:w-50por'" :show="show" @close="show = false">
            <div class="flex flex-col h-full">
                <ModalHeader :type="'show'" :entity="'status'" @close="show = false"/>
                <div class="flex flex-col h-full p-4">
                    <div v-if="hpStatusResponse" class="h-full w-auto flex flex-row justify-center items-center text-lg">
                        <div class="flex flex-col justify-center items-center">
                            <div v-if="hpStatusResponse.conn_mode === 'oAuth2'" class="flex flex-col w-full">
                                <div class="my-4">
                                    <div v-if="hpStatusResponse.conn_status === 'OK'" class="flex flex-col h-full justify-center items-center">
                                        <p>El estado de la integración con Hubspot es OK.</p>
                                    </div>
                                    <div v-else class="flex flex-col h-full justify-center items-center">
                                        <div v-if="hpStatusResponse.conn_status === 'EXPIRED_REFRESH_TOKEN'">
                                            <p>Expirado el oAuth2 token de la integración con Hubspot en base de datos.</p>
                                            <p>Sigue este enlace para dar el permiso requerido a la integración.</p>
                                        </div>
                                        <div v-else>
                                            <p>No existe un oAuth2 token válido para la integración de Hubspot en base de datos.</p>
                                            <p>Sigue este enlace para dar el permiso requerido a la integración.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="Connection" action="getHpConnStatus" ref="hubspotRef" @success="onSuccess" @error="onError"/>
                <a v-if="linkToHubspot" :href="hpStatusResponse.set_hp_conn_url" class="w-1/2 inline-flex justify-center rounded-md border
                    border-yellow-400 shadow-md px-4 py-2 bg-yellow-200 font-medium text-yellow-800 hover:bg-gray-50
                    sm:w-40 sm:text-sm focus:outline-none">
                    Go to Hubspot
                </a>
            </ModalFooter>
        </ModalBase>
    </div>
</template>

<script>
import {Request} from '@/api/components';
import {actions} from "@/store";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";

export default {
    name: "Status",
    components: {
        Request, ModalBase, ModalHeader, ModalFooter
    },
    data() {
        return {
            show: false,
            hubspotRef: null,
            hpStatusResponse: null
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
        linkToHubspot() {
            return !!(this.hpStatusResponse
                && this.hpStatusResponse.conn_mode === 'oAuth2'
                && this.hpStatusResponse.conn_status !== 'OK');

        }
    },
    methods: {
        refreshData() {
            this.$refs.hubspotRef.request();
        },
        onSuccess(response) {
            this.hpStatusResponse = response;
        },
        onError(errors) {
            this.toastErrors(errors)
        },
    },
    mounted() {
        this.$refs.hubspotRef.request();
    }
}
</script>

<style scoped>

</style>
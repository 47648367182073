<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-pencil text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por lg:w-70por'" :show="show" @close="show = false">
            <form @submit.prevent="store">
                <div class="flex flex-col h-full">
                    <ModalHeader :type="'show'" :editable="editable" :entity="'tipo de autenticación'" @close="show = false"/>
                    <div class="flex flex-row justify-center items-center p-4">
                        <label class="w-1/2 text-center">
                            <input v-model="form.mode" type="radio" class="form-radio" name="connectionMode" value="apikey" :disabled="!editable" :checked="form.mode === 'apikey'">
                            <span class="ml-2">Api key</span>
                        </label>
                        <label class="w-1/2 text-center">
                            <input v-model="form.mode" type="radio" class="form-radio" name="connectionMode" value="oAuth2" :disabled="!editable" :checked="form.mode === 'oAuth2'">
                            <span class="ml-2">oAuth2</span>
                        </label>
                    </div>
                </div>
                <ModalFooter @close="show = false">
                    <Request model="Connection" action="update" :form="form" @success="onSuccess" @error="onError" ref="updateConnectionRef"/>
                    <ActionBtn v-show="!editable" :action="'edit'" @edit="edit"/>
                    <ActionBtn v-show="editable" :action="'store'" @store="store"/>
                </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
import {actions} from "@/store";
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";

export default {
    name: "Mode",
    components: {
        Request, ModalBase, ModalHeader, ModalFooter, ActionBtn
    },
    data() {
        return {
            show: false,
            editable: false,
            form: {
                id: null,
                mode: null
            },
            elSelected: null
        }
    },
    methods: {
        setForm() {
            this.elSelected = actions.getElSelected();
            this.form.id  = this.elSelected.id;
            this.form.mode = this.elSelected.mode;
        },
        edit(){
            this.editable = !this.editable;
        },
        store() {
            this.$refs.updateConnectionRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$emit('refreshDataTable');
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
        },
    },
    mounted() {
        this.setForm();
    },
}
</script>

<style scoped>

</style>
